// import { Collapse } from 'bootstrap';
import React, { useContext, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
import { UserContext } from "../context/theme";
import Translator from "../components/translator/translator";
import { useState } from "react";

function SiderMenu() {
    const history = useLocation()
    const { reset, counts, sideMenuActive, setSideMenuActive } = useContext(UserContext)
    useEffect(() => {
        setSideMenuActive(false)
        document.body.className = document.body.className.replace("overflowHidn", "");
    }, [history])
    const toggleSidebar = () => {
        setSideMenuActive(!sideMenuActive)
        if (sideMenuActive) {
            document.body.className = ""
        } else {
            document.body.className = "overflowHidn"
        }
    }
    const navigate = useNavigate();
    const hanleStore = () => {
        toast.warning("Please create store before adding product");
        navigate("/store");
    };


   

    // useEffect(() => {
    //     var myCollapse = document.getElementById('collapseTarget')
    //     var bsCollapse = new Collapse(myCollapse, {toggle: false})
    //     bsCollapse.show()
    // })
    return (
        <>
            <div className={`left-sidebar-wrapper ${sideMenuActive ? "active" : ""}`}>
                <div className={`leftSideBrBackGrnd ${sideMenuActive ? "active" : ""}`} onClick={() => toggleSidebar()}></div>
                <nav className="sidebar">
                    <div className="left-nav-menu">
                        <DropDownMenu>
                            <Menu path="/dashboard" title="Dashboard" icon="radix-icons:dashboard" iconifyIcon="true" />


                            <Menu path="/manage/store" title="Manage Store" icon="mdi:store-cog" iconifyIcon="true" />


                            <Dropdown title="Manage Orders" icon="material-symbols:manage-accounts-outline-rounded" iconifyIcon="true">
                                <Menu path="/order/list" title={`Order List`} iconifyIcon="true" />
                                <Menu path="/bulk-order/inquiry" title={`Bulk Order Inquiry `} iconifyIcon="true" />
                                <Menu path="/store/view/inquiry" title={`Store Contacted`} iconifyIcon="true" />
                                <Menu path="/products/inquiry" title={`Products Enquiry`} iconifyIcon="true" />
                                <Menu path="/services/inquiry" title={`Services Contacted`} iconifyIcon="true" />

                            </Dropdown>

                            <Menu path="/mywork/list" title="My Work" icon="material-symbols:work-outline" iconifyIcon="true" />
                            <Menu path="/promocode" title="Manage Promo Codes" icon="material-symbols:manage-accounts-outline-rounded" iconifyIcon="true" />
                            <Menu path="/lead/list" title="Manage Leads" icon="ri:chat-unread-line" iconifyIcon="true" />
                            <Menu path="/report" title="Manage Transactions" icon="ri:bar-chart-2-line" iconifyIcon="true" />
                            <Menu path="/help" title="Help" icon="carbon:help" iconifyIcon="true" />
                            <Menu path="/profile" title="Profile" icon="gg:profile" iconifyIcon="true" />
                            <Menu path="/subcription" title="My Subscription " icon="fa:diamond" iconifyIcon="true" />
                            <Menu path="/setting" title="Settings " icon="uil:setting" iconifyIcon="true" />

                            <li>
                                <a className="collapse has_menu " onClick={() => reset()}>
                                    <span>
                                        <iconify-icon icon="mdi:logout"></iconify-icon>
                                    </span>
                                    <span className="menu_txt">Sign Out </span>
                                </a>
                            </li>
                        </DropDownMenu>
                    </div>
                </nav >
            </div >
        </>
    );
}
export default SiderMenu;

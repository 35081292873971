let ROOT_URL = "";
let IMAGE_URL = "";
let socketConnectionURL = "";
// console.log(process.env.REACT_APP_SERVER);
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://api.foundationbrickindia.in/api/v1";
  socketConnectionURL = "https://api.foundationbrickindia.in";
  IMAGE_URL = "";
} else {
  ROOT_URL = "https://beta.api.foundationbrickindia.in/api/v1";
  // ROOT_URL = "http://localhost:8000/api/v1";
  socketConnectionURL = "https://beta.api.foundationbrickindia.in";
  IMAGE_URL = "";
}
export { IMAGE_URL, socketConnectionURL };
export default ROOT_URL;
